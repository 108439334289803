import { useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { Icon } from '@components/images/renderSvg'
import { useHover } from 'hooks'
import Link from 'next/link'
import { useOnClickOutside } from '@hooks'
import { programLinks } from '../navLinks'

const Container = styled.div`
  position: relative;
  cursor: pointer;
  z-index: 99;
`

const Options = styled.div`
  margin-top: 5px;
  border-radius: 10px;
  padding: 2px;
  display: flex;
  flex-direction: column;
  position: absolute;
  background: ${(props) => props.theme.colors.white};
  z-index: 1;
  box-shadow: 2px 2px 8px black;
`

const NavButton = styled.div`
  display: flex;
  align-items: center;
  border-radius: 5px;
  a {
    :active {
      color: ${(props) => props.theme.colors.secondary};
      background: none;
    }
  }
`

const Programs = styled.a`
  padding-right: 2px !important;
  width: 50px;
  color: ${(props) =>
    props.isHovered || props.open
      ? `${props.theme.colors.secondary} !important`
      : props.theme.colors.black};
`

export const ProgramsDropdown = () => {
  const [open, setOpen] = useState(false)
  const containerRef = useOnClickOutside(() => setOpen(false))
  const theme = useTheme()
  const [navButtonRef, isHovered] = useHover()
  return (
    <Container ref={containerRef}>
      <NavButton ref={navButtonRef} onClick={() => setOpen(!open)}>
        <Programs isHovered={isHovered} open={open}>
          Programs
        </Programs>

        {open ? (
          <Icon name="expand-less" color={theme.colors.secondary} />
        ) : (
          <Icon name="expand-more" color={isHovered ? theme.colors.secondary : 'black'} />
        )}
      </NavButton>
      <div>
        {open && (
          <Options onClick={() => setOpen(false)}>
            {Object.values(programLinks).map(({ title, href }) => (
              <Link key={href} href={href}>
                <a>{title}</a>
              </Link>
            ))}
          </Options>
        )}
      </div>
    </Container>
  )
}
