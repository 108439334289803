import { useQuery } from 'react-query'
import esAPI from 'services/esAPI/esAPI'

const initialData = {
  payments: [],
}

export const useScheduledPayments = (options = {}) =>
  useQuery('accel-install-payments', () => esAPI('get', '/payments/accel-install-payments'), {
    initialData,
    staleTime: 2000,
    refetchOnMount: 'always',
    ...options,
  })
