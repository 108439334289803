import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { media } from 'styled-bootstrap-grid'

const NavList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: ${(props) => (props.vertical ? 'column' : 'row')};
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  ${media.md`
    padding-left: 10px;
  `}
`

const Nav = ({ vertical = false, children, ...props }) => (
  <nav>
    <NavList {...props} vertical={vertical}>
      {children}
    </NavList>
  </nav>
)

Nav.propTypes = {
  /** Switch from a horizontal nav menu to a vertical list */
  vertical: PropTypes.bool,
}

export default Nav
