import styled from 'styled-components'

const H1 = styled.h1`
  font-family: Montserrat, sans-serif;
  font-size: 0;
  width: 1px;
  height: 1px;
  display: inline-block;
  overflow: hidden;
  position: absolute !important;
  border: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  clip: rect(1px, 1px, 1px, 1px);
`

export default H1
