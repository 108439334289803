import { useState } from 'react'
import esAPI from '../services/esAPI/esAPI'

export const useVerifyToken = () => {
  const [isValidToken, setIsValidToken] = useState(false)
  const [loading, setLoading] = useState(true)

  const verifyToken = token => {
    setLoading(true)

    return esAPI('get', `/auth/verify-token/${token}`)
      .then(res => setIsValidToken(res.isValidToken))
      .finally(() => setLoading(false))
  }

  return { isValidToken, loading, verifyToken }
}
