import { useQuery } from 'react-query'
import { esAPI } from 'services/esAPI/esAPI'

const initialData = { user: null }

export const useUserQuery = (options = {}) => {
  return useQuery(
    'user-query',
    ({ userToken }) => esAPI('get', `/auth/me?userToken=${userToken || ''}`),
    {
      initialData,
      staleTime: 2000,
      refetchOnMount: 'always',
      ...options,
    },
  )
}
