import { useEffect, useState } from 'react'
import { useUserQuery } from 'api'
import { useRouter } from 'next/router'

export const useTokenSignIn = () => {
  const router = useRouter()
  const { signInToken } = router.query
  const {
    data: { user },
    refetch,
  } = useUserQuery({ enabled: false, refetchOnMount: false })

  const [tokenSignIn, setTokenSignIn] = useState({ loading: true, success: undefined })

  useEffect(() => {
    if (user || !signInToken) setTokenSignIn({ loading: false })
    else {
      refetch({ signInToken })
        .then((result) => {
          const urlWithoutParams = router.asPath.split('?')[0]
          router.replace(urlWithoutParams, urlWithoutParams, { shallow: true })

          setTokenSignIn({ loading: false, success: !!result })
        })
        .catch((err) => {
          setTokenSignIn({ loading: false, success: false })
          // ignore error
        })
        .finally(() => {
          sessionStorage.removeItem('signInToken')
        })
    }
  }, [router, signInToken, refetch, user])

  return { tokenSignIn }
}

export const TokenSignInProvider = ({ children }) => {
  useTokenSignIn()

  return children
}
