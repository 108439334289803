import { useMutation, useQueryClient } from 'react-query'
import noop from 'lodash/noop'
import { successToast } from 'services/Notification/toastNotifications'
import esAPI from 'services/esAPI/esAPI'

export const useUpdateCardDetailsMutation = ({ onSuccess = noop } = {}) => {
  const queryClient = useQueryClient()

  const mutation = useMutation(
    'update-card-details-mutation',
    (vals) => esAPI('post', '/stripe/update-card', vals),
    {
      onSuccess: ({ card }) => {
        queryClient.setQueryData('card-details-query', { card })
        queryClient.invalidateQueries('accel-install-payments')
        queryClient.invalidateQueries('install-plan')
        successToast('Card Details Updated!')
        onSuccess()
      },
    },
  )

  return { ...mutation, updateCardDetails: mutation.mutateAsync }
}
