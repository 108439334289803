import { useEffect, useRef } from 'react'

export const useWindowExitTrigger = ({ callback, limit = Infinity }) => {
  const triggerCount = useRef(0)

  useEffect(() => {
    const cb = (event) => {
      if (mouseIsOutsideWindow(event) && triggerCount.current < limit) {
        triggerCount.current++
        callback()
      }
    }

    document.addEventListener('mouseleave', cb)

    return () => document.removeEventListener('mouseleave', cb)
  }, [limit, callback])
}

const mouseIsOutsideWindow = (event) =>
  event.clientY <= 0 ||
  event.clientX <= 0 ||
  event.clientX >= window.innerWidth ||
  event.clientY >= window.innerHeight
