import { useQuery } from 'react-query'
import esAPI from 'services/esAPI/esAPI'

const initialData = {
  card: {},
}

export const useCardDetailsQuery = (options = {}) =>
  useQuery('card-details-query', () => esAPI('get', '/stripe/card-details'), {
    initialData,
    staleTime: 2000,
    refetchOnMount: 'always',
    ...options,
  })
