import { css } from 'styled-components'

export const slideInOut = css`
  animation-fill-mode: forwards;
  animation-duration: 0.2s;
  animation-name: ${(props) => (props.displayed ? 'slidein' : 'slideout')};
  opacity: 0;
  transition: all 600ms ease-in;

  @keyframes slidein {
    from {
      opacity: 0;
      transform: translateY(-100%);
    }

    to {
      opacity: 1;
      transform: translateY(0%);
    }
  }

  @keyframes slideout {
    from {
      opacity: 1;
      transform: translateY(0%);
    }

    to {
      opacity: 0;
      transform: translateY(-100%);
    }
  }
`

export const shadows = {
  strong: css`
    box-shadow: 2px 2px 10px gray;
  `,
  subtle: css`
    box-shadow: 0 5px 12px 0 rgb(33 43 54 / 7%), 0 10px 30px 0 rgb(33 43 54 / 9%);
  `,
}

export const absoluteFill = css`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`
