import { useMutation, useQueryClient } from 'react-query'
import noop from 'lodash/noop'
import esAPI from 'services/esAPI/esAPI'

export const useRegisterMutation = ({ onSuccess = noop } = {}) => {
  const queryClient = useQueryClient()

  const mutation = useMutation(
    'register-mutation',
    (vals) => esAPI('post', '/auth/register', vals),
    {
      onSuccess: (user) => {
        queryClient.setQueryData('user-query', { user })
        onSuccess()
      },
    },
  )

  return { ...mutation, register: mutation.mutateAsync }
}
