import { toast } from 'react-toastify'

// https://fkhadra.github.io/react-toastify/

export const successToast = (content, options = {}) =>
  toast.success(content, {
    progress: undefined,
    ...options,
  })

export const infoToast = (content, options = {}) =>
  toast.info(content, {
    progress: undefined,
    ...options,
  })

export const warningToast = (content, options = {}) =>
  toast.warn(content, {
    progress: undefined,
    ...options,
  })

export const errorToast = (content, options = {}) =>
  toast.error(content, {
    progress: undefined,
    ...options,
  })

export const Toast = (content, options = {}) =>
  toast(content, {
    progress: undefined,
    ...options,
  })
