import styled, { css } from 'styled-components'

export const pStyles = css`
  font-weight: 400;
  font-size: ${(props) => props.theme.fontSizes.md}px;
  font-family: Montserrat, sans-serif;
  margin-bottom: 20px;
  color: ${(props) => props.theme.colors.paragraph};
`

const P = styled.p`
  ${pStyles}
`

/** @component */
export default P
