import { useQuery } from 'react-query'
import { orderBy } from 'lodash/fp'
import esAPI from 'services/esAPI/esAPI'

const orderByDate = orderBy(['date'], ['asc'])

const initialData = []

export const useLiveSessionsQuery = (limit) => {
  const query = useQuery(
    'live-sessions-query',
    () => esAPI('get', `/course/live-sessions/${limit || ''}`),
    { initialData },
  )

  return { ...query, liveSessions: orderByDate(query.data) }
}
