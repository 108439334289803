import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js'
import esAPI from '../../services/esAPI/esAPI'
import { paymentErrors } from './paymentErrors'

export const useStripePayment = ({ makePurchase, instalmentPlanId, product, promoCode }) => {
  const stripe = useStripe()
  const elements = useElements()

  const getStripePaymentIntentClientSecret = () =>
    esAPI('post', '/purchase/stripe/intent', {
      product,
      promoCode,
      instalmentPlanId,
    }).then((data) => data.clientSecret)

  const handleStripePurchase = async ({ name, ...userDetails }) => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      throw Error(paymentErrors.generic)
    }

    const card = elements.getElement(CardElement)
    if (!card) throw Error(paymentErrors.generic)

    const clientSecret = await getStripePaymentIntentClientSecret()
    const result = await stripe.confirmCardPayment(clientSecret, { payment_method: { card } })

    if (result.error) throw result.error.message

    if (result.paymentIntent.status !== 'succeeded') {
      throw Error(paymentErrors.notProcessed)
    }

    return makePurchase({
      ...userDetails,
      product,
      instalmentPlanId,
      stripeIntentId: result.paymentIntent.id,
    })
  }

  return {
    handleStripePurchase,
  }
}
