const tokenKey = 'entrSem-auth'

class TokenStore {
  token = ''

  constructor() {
    if (typeof window !== 'undefined') {
      this.token = window.localStorage.getItem(tokenKey) ?? ''
    }
  }

  set(token) {
    this.token = token
    window.localStorage.setItem(tokenKey, token)
  }

  get() {
    return this.token
  }

  clear() {
    this.set('')
  }
}

export const authTokenStore = new TokenStore()
