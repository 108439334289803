import styled, { css } from 'styled-components'
import { media } from 'styled-bootstrap-grid'

export const h3Styles = css`
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  font-size: 1em;
  margin: 0;
  margin-bottom: ${(props) => props.theme.spacing.sm}px;

  ${media.sm`
    font-size: 0.83em;
  `}
`

const H3 = styled.h3`
  ${h3Styles}
`

/** @component */
export default H3
