import getConfig from 'next/config'
import { authTokenStore } from 'services/esAPI/authTokenStore'
import { createUrlParamsObj } from 'utils/createUrlParamsObj'

const { API_HOST: uri } = getConfig().publicRuntimeConfig

const empty = {}

export const esAPI = (method, path, data, { body, headers = empty } = empty) => {
  const options = {
    body,
    headers,
    mode: 'cors',
    credentials: 'include',
    method: method.toUpperCase(),
  }

  if (data) {
    headers['Content-Type'] = 'application/json'
    options.body = JSON.stringify(data)
  }

  headers['Authorization'] = authTokenStore.get()

  // Return an object containing the url parameters
  const queryParams = createUrlParamsObj()
  // Convert the parameters object to URL encoded string
  const queryParamsString = Object.entries(queryParams)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&')

  const urlWithParams = queryParamsString ? `${uri}${path}?${queryParamsString}` : `${uri}${path}`
  return fetch(urlWithParams, options).then((res) => {
    if (res.ok && res.headers.has('Authorization')) {
      authTokenStore.set(res.headers.get('Authorization'))
    }
    return res.ok ? res.json() : res.json().then((result) => Promise.reject(result))
  })
}

export default esAPI
