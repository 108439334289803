import { useLayoutEffect, useRef, useState } from 'react'

/**
 * Attach targetRef to the component whose dimensions you need
 */

export const useTargetDimensions = () => {
  const targetRef = useRef()
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

  useLayoutEffect(() => {
    if (targetRef.current) {
      setDimensions({
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight,
      })
    }
  }, [])

  return {
    targetRef,
    width: dimensions.width,
    height: dimensions.height,
  }
}
