import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import esAPI from 'services/esAPI/esAPI'

const SUBSCRIPTION_ENDPOINT_PATH = '/stripe/subscription'

/**
 * Set up using Stripe guide: https://stripe.com/docs/billing/subscriptions/fixed-price
 *
 * For now skipping the retry-invoice section, since this only happens when card is completely declined
 * The user should be able to repurchase anyway using a different card
 */

export const useStripeSubscription = ({ priceId } = {}) => {
  const stripe = useStripe()
  const elements = useElements()

  const createPaymentMethodAndSubscribe = ({ name, email }) => {
    const card = elements.getElement(CardElement)

    return stripe
      .createPaymentMethod({
        card,
        type: 'card',
        billing_details: { name, email },
      })
      .then(validateNoError)
      .then((result) => createSubscription({ paymentMethodId: result.paymentMethod.id }))
  }

  const createSubscription = ({ paymentMethodId }) =>
    esAPI('post', SUBSCRIPTION_ENDPOINT_PATH, { paymentMethodId, priceId })
      .then(validateNoError)
      // Normalize the result to contain the object returned by Stripe.
      // Add the additional details we need.
      .then((subscription) => {
        return {
          paymentMethodId,
          subscription,
        }
      })
      .then(handlePaymentThatRequiresCustomerAction)
      .then(handleRequiresPaymentMethod)
      .then(onSubscriptionComplete)

  const onSubscriptionComplete = (result) => {
    // Payment was successful.

    return esAPI('post', '/stripe/subscription-completion', {
      subscriptionId: result.subscription.id,
    })
  }

  const handlePaymentThatRequiresCustomerAction = ({
    subscription,
    invoice,
    paymentMethodId,
    isRetry,
  }) => {
    if (subscription?.status === 'active') {
      // Subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId }
    }

    // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
    // If it's a retry, the payment intent will be on the invoice itself.
    const paymentIntent = invoice
      ? invoice.payment_intent
      : subscription?.latest_invoice.payment_intent

    if (
      paymentIntent.status === 'requires_action' ||
      (isRetry && paymentIntent.status === 'requires_payment_method')
    ) {
      return stripe
        .confirmCardPayment(paymentIntent.client_secret, { payment_method: paymentMethodId })
        .then(validateNoError)
        .then((result) => {
          if (result.paymentIntent.status === 'succeeded') {
            return {
              subscription,
              invoice,
              paymentMethodId,
              priceId,
            }
          }
        })
    } else {
      // No customer action needed.
      return { subscription, priceId, paymentMethodId }
    }
  }

  const handleRequiresPaymentMethod = ({ subscription, paymentMethodId }) => {
    if (subscription.status === 'active') {
      // subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId }
    } else if (subscription.latest_invoice.payment_intent.status === 'requires_payment_method') {
      throw Error('Your card was declined.')
    } else {
      return { subscription, priceId, paymentMethodId }
    }
  }

  return {
    createPaymentMethodAndSubscribe,
  }
}

const validateNoError = (result) => {
  if (result.error) {
    throw result.error
  }
  return result
}
