import { useRouter } from 'next/router'
import { useCallback, useEffect, useState } from 'react'

const UTM_PARAMS = 'utmParams'
const URL_PARAMS = 'urlParams'

const parsedOrEmptyObj = key => {
  const storedParams = sessionStorage[key]
  return !storedParams ? {} : JSON.parse(storedParams)
}

export const getUrlParams = () => parsedOrEmptyObj(URL_PARAMS)

export const getUtmParams = () => parsedOrEmptyObj(UTM_PARAMS)

export const getReferrerId = () => getUrlParams().referrerId

export const useUrlParams = () => {
  const { query } = useRouter()
  const [urlParams, setUrlParams] = useState({})
  const [utmParams, setUtmParams] = useState({})

  useEffect(() => {
    storeUrlParams()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const storeUtmParams = useCallback(() => {
    if (Object.values(query).length > 0 && !sessionStorage[UTM_PARAMS]) {
      const {
        utm_source: utmSource,
        utm_medium: utmMedium,
        utm_campaign: utmCampaign,
        utm_term: utmTerm,
        utm_content: utmContent,
      } = query

      const params = { utmCampaign, utmMedium, utmSource, utmTerm, utmContent }
      setUtmParams(params)

      sessionStorage.setItem(UTM_PARAMS, JSON.stringify(params))
    }
  }, [query])

  const storeUrlParams = useCallback(() => {
    if (Object.values(query).length === 0) return
    const existingParams = getUrlParams()
    const updatedParams = { ...existingParams, ...query }
    setUrlParams(updatedParams)
    sessionStorage.setItem(URL_PARAMS, JSON.stringify(updatedParams))
    storeUtmParams()
  }, [query, storeUtmParams])

  return {
    storeUrlParams,
    urlParams,
    utmParams,
  }
}
