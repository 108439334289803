export const createUrlParamsObj = () => {
  const urlParams = new URLSearchParams(window.location.search)

  const queryParams = {}
  urlParams.forEach((value, key) => {
    queryParams[key] = value
  })

  return queryParams
}
