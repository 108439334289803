import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

const H4 = styled.h4`
  font-family: Montserrat, sans-serif;
  font-weight: normal;
  font-size: ${(props) => props.theme.fontSizes.md}px;
  margin-block-start: ${(props) => props.theme.spacing.sm}px;

  ${media.md`
    font-size: ${(props) => props.theme.fontSizes.larger}px;
  `}
`

/** @component */
export default H4
