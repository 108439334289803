import getConfig from 'next/config'

const { GA_TRACKING_ID } = getConfig().publicRuntimeConfig

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const gtagPageView = url =>
  window.gtag &&
  window.gtag('config', GA_TRACKING_ID, {
    page_path: url,
  })

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
const gtagEvent = ({ event, category, label, value }) =>
  window.gtag &&
  window.gtag('event', event, {
    event_category: category,
    event_label: label,
    value: value,
  })

export function gaCompletedRegistration() {
  gtagEvent({
    event: 'CompletedRegistration',
    category: 'user',
  })
}

export function gaInitiatedCheckout({ product }) {
  gtagEvent({
    event: 'InitiatedCheckout',
    category: 'package',
    label: product,
  })
}

export function gaLead({ type }) {
  gtagEvent({
    event: 'Lead',
    category: type,
  })
}
