import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

const NavItem = styled.li`
  font-weight: 500;
  display: block;
  width: 100%;
  text-align: center;
  user-select: none;

  a {
    transition: 0.2s all ease-in-out;
    text-align: left;
    border-radius: 5px;
    display: block;
    white-space: nowrap;
    font-size: ${(props) => props.theme.fontSizes.medium}px;
    font-family: 'Montserrat', sans-serif;
    color: ${(props) => props.theme.colors.black};
    padding: ${(props) => props.theme.spacing.md}px;
    width: 100%;
    &:hover {
      cursor: pointer;
    }
  }

  ${media.md`
    width: auto;
    /* font-size: 18px; */
    a {
      width: auto;
      padding: ${(props) => `${props.theme.spacing.md}px ${props.theme.spacing.md}px`};
      font-size: ${(props) => props.theme.fontSizes.lg}px;
    &:hover {
      cursor: pointer;
      color: ${(props) => props.theme.colors.secondary};
      }

    &:active {
      background: ${(props) => props.theme.colors.primary};
      color: ${(props) => props.theme.colors.white};
      }
    }
  `};

  ${media.lg`
      text-align: center;
    /* font-size: ${(props) => props.theme.fontSizes.md}px; */
  `};
`

/** @component */
export default NavItem
