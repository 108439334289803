export const mainNavItemGroups = [
  {
    title: 'Programs',
    items: [
      {
        href: '/entrepreneur-learning-mentoring-course/acceleratorProgram',
        title: 'Idea to Exit Masterclass', // Accelerator
      },
      {
        href: '/entrepreneur-learning-mentoring-course/foundationProgram',
        title: 'Foundation',
      },
      {
        href: '/entrepreneur-learning-mentoring-course/raisingFundsForBusinessProgram',
        title: 'Raising Funds for Business',
      },
      {
        href: '/entrepreneur-learning-mentoring-course/starterProgram',
        title: 'Starter',
      },
    ],
  },
  {
    title: 'Resources',
    items: [
      {
        href: '/conversations',
        title: 'Conversations',
      },
      {
        href: 'https://edu.entrepreneurseminar.com/es-free-resources',
        title: 'Free Resources',
        anchorProps: {
          target: '_blank',
        },
      },
    ],
  },
  {
    title: 'Who are we',
    items: [{ href: '/about-martin-warner', title: 'About Martin' }],
  },
  {
    title: 'Questions',
    items: [{ href: '/contact-martin-warner', title: 'Contact Us' }],
  },
]

export const secondaryNavItems = [
  { href: '/privacy-policy', title: 'Privacy Policy' },
  { href: '/disclaimer', title: 'Earnings and Income Disclaimer' },
  { href: '/terms', title: 'Terms of Use' },
]

export const socialLinks = [
  { icon: 'twitter', href: 'https://twitter.com/MartinWarner' },
  { icon: 'instagram', href: 'https://www.instagram.com/martinwarnerofficial/' },
  { icon: 'youtube', href: 'https://www.youtube.com/channel/UCqXKrlpDuMz0Cr7lOyiL51g' },
  { icon: 'linkedin', href: 'https://www.linkedin.com/in/warnermartin' },
]
