import styled from 'styled-components'
import { pStyles } from '../P/P'

const Anchor = styled.a`
  ${pStyles};
  text-decoration: underline;
  transition: all 0.2s ease;

  :hover {
    text-decoration-line: underline;
    color: ${(props) => props.theme.colors.secondary};
  }
`

/** @component */
export default Anchor
