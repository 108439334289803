import React from 'react'
import styled from 'styled-components'
import Link from 'next/link'
import { media } from 'styled-bootstrap-grid'

const AWrapper = styled.a`
  ${media.md`
    margin-right: auto;
  `};
`

const LogoImg = styled.img`
  height: 45px;
  width: auto;

  &:hover {
    cursor: pointer;
  }

  ${media.md`
    height: 56px;
  `};

  ${media.lg`
    height: 64px;
  `};
`

const LogoLink = ({ className, style }) => (
  <Link href="/">
    <AWrapper>
      <LogoImg src="/static/es-logo.png" alt="logo" className={className} style={style} />
    </AWrapper>
  </Link>
)

export default LogoLink
