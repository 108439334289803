import React from 'react'
import PropTypes from 'prop-types'
import { createGlobalStyle } from 'styled-components'
import CSSTransition from 'react-transition-group/CSSTransition'

const SlideDownGlobalStyle = createGlobalStyle`
  .slide-down {
    position: relative;
  }
  
  .slide-down-enter {
    transform: translateY(-100%);
  }
  .slide-down-enter-active {
    transform: translateY(0%);
    transition: transform 300ms ease-out;
  }
  
  .slide-down-exit {
    opacity: 1;
    transform: translateY(0%);
  }
  .slide-down-exit-active {
    opacity: 0;
    transform: translateY(-100%);
    transition: all 300ms ease-in;
  }
`

const AnimateSlideDown = ({ children, ...props }) => (
  <>
    <SlideDownGlobalStyle />
    <CSSTransition unmountOnExit {...props} classNames="slide-down" timeout={300}>
      {children}
    </CSSTransition>
  </>
)

AnimateSlideDown.propTypes = {
  in: PropTypes.bool.isRequired,
}

export default AnimateSlideDown
