import styled from 'styled-components'
import PropTypes from 'prop-types'

const Button = styled.button`
  user-select: none;
  padding: ${(props) => `${props.theme.spacing.md}px ${props.theme.spacing.lg}px`};
  border: 1px solid ${(props) => props.primary || props.theme.colors.secondary};
  border-radius: 10px;
  border-color: ${(props) => props.theme.colors.primary};
  font-family: 'Montserrat', sans-serif;
  font-size: ${(props) => props.theme.fontSizes.large}px;
  font-weight: ${(props) => (props.unbold ? 500 : 700)};
  margin: 0;
  margin-bottom: ${(props) => (props.marginBottom ? props.theme.spacing[props.marginBottom] : 0)}px;
  cursor: pointer;
  width: ${(props) => (props.width ? props.width : props.block || props.fullWidth ? '100%' : 'auto')};
  white-space: nowrap;
  color: ${(props) => (props.color ? props.color : props.theme.colors.white)};
  align-self: ${(props) => (props.autoAlign ? 'auto' : 'center')};
  transition: all 0.1s ease-in-out;
  background: ${(props) =>
    props.backgroundColor
      ? props.theme.colors[props.backgroundColor] ?? props.backgroundColor
      : props.theme.colors.primary};
  padding: ${(props) => `${props.theme.spacing.md}px ${props.theme.spacing.lg}px`};
  ${(props) => props.noBorder && 'border: none;'}

  :hover {
    background: ${(props) => props.theme.colors.secondary};
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }
`

Button.propTypes = {
  fullWidth: PropTypes.bool,
  bold: PropTypes.bool,
  color: PropTypes.string,
  background: PropTypes.string,
  marginBottom: PropTypes.string,
  /** Display the button as a link without a background */
  link: PropTypes.bool,
  /** Make the button span the full parent width
   * @deprecated use `fullWidth`
   * */
  block: PropTypes.bool,
}

Button.defaultProps = {
  link: false,
}

export const PrimaryCTA = Button

export const SecondaryCTA = styled(Button)`
  align-self: center;
  background: ${(props) => props.theme.colors.transparent};
  padding: ${(props) => `${props.theme.spacing.md}px ${props.theme.spacing.lg}px`};
  color: ${(props) => props.theme.colors.grayDark};
  font-weight: 100;
  font-size: ${(props) => props.theme.fontSizes.large}px;

  :hover {
    color: ${(props) => props.theme.colors.white};
    background: ${(props) => props.theme.colors.primary};
  }
`

export default PrimaryCTA
