import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { scrollToId } from 'utils/navigation'

export const useScrollToSection = () => {
  const router = useRouter()
  let section = router.asPath.split('#')[1]

  useEffect(() => {
    let timeoutId
    /**
     * The check for history length here is to force the page to scroll to the desired section ONLY on initial visits;
     * which more often than not emanates from clicking the link to the page from third party platforms like email CTAs.
     * If user clicks the browser back button after navigating to another page, they will meet the destination page from start and
     * not be scrolled to the section again and again which may not be a good experience.
     */
    if (window?.history?.length <= 2) {
      timeoutId = scrollToSection(1000, section, () => {
        window?.scrollTo(0, 0)
      })
    }

    return () => {
      clearTimeout(timeoutId)
    }
  }, [])
}

const scrollToSection = (time, route, cb) => {
  return setTimeout(() => {
    scrollToId(route)
    cb()
  }, time)
}
