import { useQuery } from 'react-query'
import esAPI from '@services/esAPI/esAPI'

const initialData = {
  mentorshipPercentageProgress: null,
  foundationTrainingPercentageProgress: null,
  coreSessionVideoPercentageValue: [],
  coreSessionWrittenContentPercentageMetrics: [],
  coreSessionSuccessCheckListPercentageMetrics: [],
  adviceForEntrepreneursPercentageProgress: null,
  eBookLibraryPercentageProgress: null,
  trainingOn50MillionPercentageProgress: null,
  MENTORSHIP_WITH_MARTIN: {},
  FOUNDATION_TRAINING_MODULE: {},
  ADVICE_FOR_ENTREPRENEURS: {},
  EBOOK_LIBRARY: {},
  TRAINING_ON_50MM: {},
  OTHER_BENEFITS_SECTION: {},
  mentoringPortalAccessed: null,
  firstQuestionToMartin: null,
  threeQuestionsToMartin: null,
  identifyingEntrepreneurshipOpportunity: null,
  writtenContent: null,
  successChecklist: null,
  startingAGoodBusiness: null,
  gettingIntoTheInvestorsMind: null,
  whatIHaveLearntAsAnEntrepreneur: null,
  secretsToGoodHiring: null,
  behindTheScenesOfAGreatProduct: null,
  bookOfSecrets: null,
  raisingFunds: null,
  expertsAdvice: null,
  trainingOn50Million: null,
  userHasAccessedMentoringPortal: null,
  userHasAskedAtLeastOneQuestion: null,
  userHasAskedAtLeastThreeQuestions: null,
  userHasViewedFirstCoreSessionVideo: null,
  writtenContentAccessed: null,
  successChecklistAccessed: null,
  firstConversationVideoPercentageValue: null,
  secondConversationVideoPercentageValue: null,
  bookOfSecretsHasBeenAccessed: null,
  raisingMoneyHasBeenAccessed: null,
  expertAdviceHasBeenAccessed: null,
  bonusVideoOn50MillionPercentageValue: null,
  martinsBlog: null,
  referrals: null,
  testimonials: null,
  customerSupport: null,
  allPercentageProgress: [],
  averagePercentageCompletion: 0,
}

export const useFoundationContentTrackingQuery = (userId, options = {}) => {
  const query = useQuery(
    `foundation-content-tracking-${userId}`,
    () => esAPI('get', `/track/all-foundation-content/${userId}`),
    {
      initialData,
      ...options,
    },
  )

  return { ...query, contentTracking: query.data }
}
