import { useEffect, useState } from 'react'
import { getSecondsLeft } from 'utils/date'

export const useCountdown = (futureDate) => {
  const [secondsLeft, setSecondsLeft] = useState(getSecondsLeft(futureDate))

  useEffect(() => {
    setSecondsLeft(getSecondsLeft(futureDate))
    const interval = setInterval(() => {
      setSecondsLeft(getSecondsLeft(futureDate))
    }, 1000)
    return () => clearInterval(interval)
  }, [futureDate])

  // using Math.max to prevent a render before secondsLeft updates
  return { secondsLeft: Math.max(secondsLeft, getSecondsLeft(futureDate)) }
}
