import bugsnagClient from '../bugsnag/bugsnag'
import { fbqCompletedRegistration, fbqPurchased, fbqInitiatedCheckout, fbqLead } from './facebookPixel'
import { gaCompletedRegistration, gaInitiatedCheckout, gaLead } from './googleAnalytics'
import { gtmPurchased } from './googleTagManager'
import { webJamPurchased } from './webinarJam'

export { gtagPageView } from './googleAnalytics'

function track(func, ...args) {
  try {
    func(...args)
  } catch (error) {
    bugsnagClient.notify(
      { name: 'Tracking Error', message: error.message },
      {
        severity: 'warning',
        metaData: { func: func.name, args },
      },
    )
  }
}

export function trackRegistered() {
  track(fbqCompletedRegistration)
  track(gaCompletedRegistration)
}

export function trackInitiatedCheckout({ product, currency, amount }) {
  track(fbqInitiatedCheckout, { product, currency, amount: amount / 100 })
  track(gaInitiatedCheckout, { product })
}

export function trackPurchased({ product, currency, amount, promoCode }) {
  track(fbqPurchased, { product, currency, amount: amount / 100 })
  // Tracked via Google Tag Manager rather then GA directly
  // track(gaPurchased, { id: product })
  track(gtmPurchased, { product, currency, amount: amount / 100, promoCode })
  track(webJamPurchased, { promoCode })
}

export function trackLead(type = 'newsletter', eventID) {
  track(fbqLead, { type }, eventID)
  track(gaLead, { type })
}
