import { useQuery } from 'react-query'
import esAPI from 'services/esAPI/esAPI'

const initialData = {
  currency: '',
  currencySymbol: '',
  products: {},
}

export const usePricingQuery = (options = {}) =>
  useQuery('pricing-query', () => esAPI('get', '/purchase/pricing'), {
    initialData,
    staleTime: 2000,
    ...options,
  })
