import React from 'react'
import styled from 'styled-components'
import { useUserQuery } from 'api'
import Router from 'next/router'
import { authTokenStore } from 'services/esAPI/authTokenStore'
import { useUserContext } from '@containers/UserContext/UserContext'
import { Footer } from './Footer/Footer'
import Header from './Header/Header'

const Container = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const PageLayout = ({ topNavActive = true, footerActive = true, children }) => {
  // this is here temporarily until we have moved all of the UserContext functionality into react-query hooks
  const { clearUser } = useUserContext()

  const {
    data: { user },
  } = useUserQuery()

  return (
    <Container>
      {topNavActive && (
        <Header
          user={user}
          signOut={async () => {
            authTokenStore.clear()
            await Router.push('/')
            // Navigate home first to avoid awkward renders on the previous page
            window.scrollTo(0, 0)
            clearUser()
          }}
        />
      )}
      <Content>{children}</Content>
      {footerActive && <Footer />}
    </Container>
  )
}

export default PageLayout
