import { useMutation, useQueryClient } from 'react-query'
import noop from 'lodash/noop'
import esAPI from 'services/esAPI/esAPI'

export const useSignInMutation = ({ onSuccess = noop } = {}) => {
  const queryClient = useQueryClient()

  const mutation = useMutation(
    'sign-in-mutation',
    (credentials) => esAPI('post', '/auth/sign-in', credentials),
    {
      onSuccess: (user) => {
        queryClient.setQueryData('user-query', { user })
        onSuccess()
      },
    },
  )

  return { ...mutation, signIn: mutation.mutate }
}
