import { useEffect, useMemo, useRef, useState } from 'react'

export const useHover = (inputRef) => {
  const [value, setValue] = useState(false)
  const localRef = useRef(null)

  const ref = useMemo(() => inputRef ?? localRef, [inputRef])

  useEffect(() => {
    const handleMouseOver = () => setValue(true)
    const handleMouseOut = () => setValue(false)
    const node = ref.current
    if (node) {
      node.addEventListener('mouseover', handleMouseOver)
      node.addEventListener('mouseout', handleMouseOut)
      return () => {
        node.removeEventListener('mouseover', handleMouseOver)
        node.removeEventListener('mouseout', handleMouseOut)
      }
    }
  }, [ref])

  return [ref, value]
}
