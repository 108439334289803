import getConfig from 'next/config'

const { WJAM_TRACKING_ENABLED: webJamEnabled } = getConfig().publicRuntimeConfig

const wjProductID = {
  PROMO: 531567,
  ES100: 531568,
}

function postToWebjam({ action, params }) {
  if (!webJamEnabled) {
    return
  }

  const urlParams = new URLSearchParams({
    ...params,
    action,
    webicode: '9d455b85fb',
  })

  return fetch(`https://events.genndi.com/tracker?${urlParams}`, {
    mode: 'cors',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  })
}

export const webJamPurchased = ({ promoCode }) => {
  return postToWebjam({
    action: 'sale',
    params: wjProductID[promoCode] && { productID: wjProductID[promoCode] },
  })
}
