import MobileDetect from 'mobile-detect'
import { breakpoints } from '../breakpoints'

// the phone value covers portrait and landscape - there's no way to tell the
// difference from the request unless we have client hints (which don't work
// on the first request anyway) or something similar
const phoneWidth = breakpoints.sm - 1

// this is tricky too - we're going by what bootstrap uses as a default but
// an ipad in portrait mode will match here even though the width might be
// 1024, 1112, or 1366. for now leave as is - in the future we could handle
// this by compiling a list of resolutions but that's a huge undertaking.
const tabletWidth = breakpoints.md - 1

// this comes from bootstrap lg which we use this as our default desktop size
// (even though technically > 991 is desktop most seem to fall here).
const desktopWidth = breakpoints.lg

// Returns the DeviceWidthProvider which will make MediaQuery return the
// correct values on the server. Include in the root component hierarchy
// on the server and omit it from the client entry point.
export const deviceWidthParser = userAgent => {
  const md = new MobileDetect(userAgent)

  const mobile = md.mobile()
  const phone = mobile === 'UnknownMobile' || md.phone()

  const width = mobile ? (phone ? phoneWidth : tabletWidth) : desktopWidth

  return {
    width,
    deviceWidth: width,
  }
}
