import { useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { Icon } from '@components/images/renderSvg'
import { View } from '@components/layout/View/View'

/*
 * Some styles in here seem to overridden by those in `/components/navigation/Nav/Nav.js`
 * It's not clear why this is happening. While it's not ideal, we're using `!important` where necessary
 */

const LabelAndIconWrapper = styled(View)`
  flex-direction: row !important;
  align-items: center !important;
  background: none;

  :hover {
    cursor: pointer;
  }
`

const Label = styled.a`
  background: none !important;
  flex: 0;
  padding-right: ${(props) => props.theme.spacing.xs}px !important;
  color: ${(props) =>
    props.open ? `${props.theme.colors.secondary} !important` : props.theme.colors.black};
`

const Options = styled(View)`
  padding-left: ${(props) => props.theme.spacing.md}px;
`

export const Dropdown = ({ label, options = [] }) => {
  const [open, setOpen] = useState(false)
  const theme = useTheme()
  return (
    <>
      <LabelAndIconWrapper onClick={() => setOpen(!open)}>
        <Label open={open}>{label}</Label>
        <Icon
          name={open ? 'expand-less' : 'expand-more'}
          color={open ? theme.colors.secondary : theme.colors.black}
        />
      </LabelAndIconWrapper>

      {open && (
        <Options displayed={open}>
          {options.map((o) => (
            <a key={o.title} href={o.href}>
              {o.title}
            </a>
          ))}
        </Options>
      )}
    </>
  )
}
