import React, { useContext, useEffect, useMemo, useState, useCallback, createContext, useRef } from 'react'
import { useContentAvailableQuery, useUserQuery } from 'api'
import { trackPurchased } from '../../services/engagementTracking'
import esAPI from '../../services/esAPI/esAPI'
import { useStripePayment } from './useStripePayment'
import { useStripeSubscription } from './useStripeSubscription'

export const STARTER = 'starterProgram'
export const FOUNDATION = 'foundationProgram'
export const ACCELERATOR = 'acceleratorProgram'
export const RAISING_FUNDS_FOR_BUSINESS = 'raisingFundsForBusinessProgram'
export const STARTER_UPGRADE_FOUNDATION = 'starterUpgradeFoundation'
export const STARTER_UPGRADE_ACCELERATOR = 'starterUpgradeAccelerator'
export const FOUNDATION_UPGRADE = 'foundationUpgrade'
export const FOUNDATION_UPGRADE_USED_PROMO = 'foundationUpgradeUsedPromo'
export const FOUNDATION_UPGRADE_USED_WEB25 = 'foundationUpgradeUsedWEB25'
export const MENTORING = 'mentoring'
export const RETREAT = 'entrepreneurshipMasteryRetreat'
export const SUBSCRIPTION = 'subscriptionProgram'
export const NEW_YEAR = 'NEW-YEAR'

const paymentOptionTypes = {
  subscription: 'subscription',
  instalmentPlan: 'instalmentPlan',
}

export const fetchPurchaseContent = () =>
  esAPI('get', '/purchase/pricing').then((pricing) => ({ pricing, loading: false }))

const makePurchase = (values) =>
  esAPI('post', '/purchase', values).then((result) => {
    trackPurchased(result)
    return result
  })

const PurchaseContext = createContext({})

export const PurchaseProvider = ({ children }) => {
  const [state, setState] = useState({ loading: true })
  const [promoCode, setPromoCode] = useState('')
  const [validPromo, setValidPromo] = useState(true)
  const [chosenProductSlug, setChosenProduct] = useState(STARTER)
  const [chosenPaymentOptionId, setChosenPaymentOptionId] = useState(null)
  const mounted = useRef(true)

  const { pricing = { products: {} }, loading, error } = state

  const basePricing = useMemo(() => pricing.products[chosenProductSlug] || {}, [chosenProductSlug, pricing.products])

  const chosenProduct = useMemo(
    () =>
      !basePricing
        ? {}
        : {
            ...basePricing,
            slug: chosenProductSlug,
            savings: Math.max(0, basePricing.fullPrice - basePricing.price),
          },
    [basePricing, chosenProductSlug],
  )

  const chosenPaymentOption = chosenProduct?.paymentOptions?.[chosenPaymentOptionId]
  const chosenInstalmentPlan = chosenPaymentOption?.type === paymentOptionTypes.instalmentPlan && chosenPaymentOption
  const chosenSubscriptionPlan = chosenPaymentOption?.type === paymentOptionTypes.subscription && chosenPaymentOption

  const { handleStripePurchase } = useStripePayment({
    makePurchase,
    instalmentPlanId: chosenInstalmentPlan?.id,
    product: chosenProductSlug,
    promoCode,
  })

  const { createPaymentMethodAndSubscribe } = useStripeSubscription({
    priceId: chosenSubscriptionPlan?.id,
  })

  useEffect(() => {
    return () => {
      mounted.current = false
    }
  }, [])

  const setStateIfPresent = useCallback((newState) => {
    mounted.current && newState && setState(newState)
  }, [])

  const updateContent = useCallback(() => {
    if (!state.pricing) {
      setStateIfPresent({ ...state, error: null, loading: true })
      fetchPurchaseContent()
        .then(setStateIfPresent)
        .catch((error) => setStateIfPresent({ error, loading: false }))
    }
  }, [setStateIfPresent, state])

  const { contentAvailable } = useContentAvailableQuery()
  const {
    data: { user },
  } = useUserQuery()

  const createPaypalOrder = (product, promoCode) =>
    esAPI('post', '/purchase/paypal/create-transaction', {
      product,
      promoCode,
    }).then((data) => data.orderID)

  const approvePaypalOrder = (product, promoCode, paypalOrderId, referrerUserId) =>
    makePurchase({
      product,
      promoCode,
      paypalOrderId,
      referrerUserId,
    })

  const validatePromoCode = useCallback(
    (newCode = '') => {
      setPromoCode(newCode)
      return esAPI('post', '/purchase/validate-promo', { promoCode: newCode })
        .then((newPricing) => {
          setStateIfPresent({ ...state, pricing: newPricing })

          const oldPrice = basePricing.price
          const newPrice = newPricing.products[chosenProductSlug].price

          // Flag promo discount instalment specific
          const promoInstalment = newPricing.products[chosenProductSlug]?.promoInstalment

          setValidPromo(promoInstalment || newPrice < oldPrice)
        })
        .catch((error) => setStateIfPresent({ error }))
    },
    [basePricing.price, chosenProductSlug, setStateIfPresent, state],
  )

  const dynamicMakePurchase = useCallback(
    ({ name, ...vals }) => {
      if (chosenProductSlug === SUBSCRIPTION) {
        return createPaymentMethodAndSubscribe({ name, email: vals.email }).then(() => ({
          product: SUBSCRIPTION,
        }))
      } else {
        return handleStripePurchase({ name, ...vals })
      }
    },
    [chosenProductSlug, createPaymentMethodAndSubscribe, handleStripePurchase],
  )
  const availableProducts = useMemo(
    () =>
      Object.entries(
        getValidOptions({
          contentAvailable,
          promoCode: user && user.promoCode,
          products: pricing.products,
        }),
      ).filter(([slug, product]) => (product?.price || product?.paymentOptions) && slug !== chosenProductSlug),
    [chosenProductSlug, pricing.products, user, contentAvailable],
  )

  const contextValue = {
    approvePaypalOrder,
    availableProducts,
    chosenPaymentOption,
    chosenProduct,
    createPaypalOrder,
    currency: pricing.currency,
    error,
    loading,
    makePurchase: dynamicMakePurchase,
    numInstalments: !chosenInstalmentPlan ? 1 : chosenInstalmentPlan.numInstalments,
    products: pricing.products,
    promoCode,
    setPurchaseContent: setStateIfPresent,
    setChosenPaymentOptionId,
    setChosenProduct: (product) => {
      setChosenProduct(product)
      setChosenPaymentOptionId(null)
    },
    updateContent,
    validatePromoCode,
    validPromo,
  }

  return <PurchaseContext.Provider value={contextValue}>{children}</PurchaseContext.Provider>
}

export const usePurchaseContext = () => {
  const context = useContext(PurchaseContext)

  useEffect(() => {
    context.updateContent()
  }, [])

  return context
}

function getValidOptions({ promoCode, contentAvailable, products }) {
  const {
    starterProgram,
    foundationProgram,
    acceleratorProgram,
    raisingFundsForBusinessProgram,
    starterUpgradeFoundation,
    starterUpgradeAccelerator,
    foundationUpgrade,
    foundationUpgradeUsedPromo,
    foundationUpgradeUsedWEB25,
    // subscriptionProgram,
  } = products

  const mainProductsOnly = {
    starterProgram,
    foundationProgram,
    acceleratorProgram,
    raisingFundsForBusinessProgram,
    // subscriptionProgram,
  }

  if (!contentAvailable) return mainProductsOnly

  if (contentAvailable.subscription) {
    return contentAvailable[ACCELERATOR]
      ? {}
      : {
          acceleratorProgram,
        }
  }

  if (contentAvailable[ACCELERATOR]) return {}

  if (contentAvailable[FOUNDATION]) {
    if (promoCode) {
      return {
        // subscriptionProgram,
        foundationUpgradeUsedWEB25,
        ...(promoCode === 'WEB25' ? {} : foundationUpgradeUsedPromo),
      }
    }

    return {
      // subscriptionProgram,
      foundationUpgrade,
    }
  }

  if (contentAvailable[STARTER]) {
    return {
      starterUpgradeFoundation,
      starterUpgradeAccelerator,
      // subscriptionProgram,
    }
  }

  if (contentAvailable[RAISING_FUNDS_FOR_BUSINESS]) {
    return {
      raisingFundsForBusinessProgram,
    }
  }

  return mainProductsOnly
}
