import { useMutation, useQueryClient } from 'react-query'
import noop from 'lodash/noop'
import { successToast } from 'services/Notification/toastNotifications'
import esAPI from 'services/esAPI/esAPI'

export const useSchedulePaymentMutation = ({ onSuccess = noop } = {}) => {
  const queryClient = useQueryClient()

  const mutation = useMutation(
    'schedule-payment-mutation',
    (values) => esAPI('post', '/payments/schedule-payment', values),
    {
      onSuccess: ({ payments }) => {
        queryClient.setQueryData('accel-install-payments', { payments })
        queryClient.invalidateQueries('install-plan')
        successToast('Payment has been scheduled!')
        onSuccess()
      },
    },
  )

  return { ...mutation, schedulePayments: mutation.mutateAsync }
}
