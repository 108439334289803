import React from 'react'
import styled from 'styled-components'
import { View } from '../View/View'

const Container = styled(View)`
  position: relative;
  min-height: ${(props) => (props.fullScreenHeight ? '100vh' : 'unset')};
  margin: 0 auto;
  margin-bottom: ${(props) => (!props.mb ? 0 : props.theme.spacing[props.mb])}px;
  width: 100%;
  padding: ${(props) => props.theme.spacing.md}px;
  max-width: ${(props) => !props.fluid && `${props.maxWidth}px`};
`

export const PageContent = React.forwardRef(
  ({ children, maxWidth = sectionWidths.wide, fullScreenHeight, fluid, ...props }, ref) => (
    <Container
      fluid={fluid}
      maxWidth={maxWidth}
      ref={ref}
      fullScreenHeight={fullScreenHeight}
      {...props}
    >
      {children}
    </Container>
  ),
)

export const sectionWidths = { wide: 1200, medium: 1000, narrow: 800 }
