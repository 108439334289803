import styled from 'styled-components'
import { View } from '@components/layout'
import { h2Styles } from './H2/H2'
import { h3Styles } from './H3/H3'
import { pStyles } from './P/P'

export const DefaultStyles = styled(View)`
  p {
    ${pStyles}
  }

  h2 {
    ${h2Styles}
  }

  h3 {
    ${h3Styles}
  }

  li {
    margin: 0;
    ${pStyles}
  }

  ul {
    /* padding: 0;
    margin: 0;
    margin-block-start: 0;
    padding-inline-start: 0 !important; */
  }
`
