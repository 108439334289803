import React from 'react'

const fontsConfig = {
  google: {
    families: [
      'Lato:300,300i,400,400i,700,700i',
      'Montserrat:300,300i,400,400i,700,700i,900,900i',
      'Fjalla One:300,300i,400,400i,700,700i',
    ],
  },
}

class WebfontProvider extends React.Component {
  state = { fontLoader: null }

  // webfont-loader requires window so only import once the client is available
  componentDidMount() {
    if (!document.documentElement.classList.contains('wf-active')) {
      import('@dr-kobros/react-webfont-loader').then(this.updateWebfonts)
    }
  }

  updateWebfonts = (fontLoader) => this.setState({ fontLoader })

  render() {
    const { children } = this.props
    const { fontLoader } = this.state
    const WebfontLoader = fontLoader && fontLoader.default
    return typeof WebfontLoader !== 'object' ? (
      <WebfontLoader config={fontsConfig}>{children}</WebfontLoader>
    ) : (
      children
    )
  }
}

export default WebfontProvider
