const theme = {
  colors: {
    primary: '#46AECF',
    secondary: '#0785AB',
    secondaryDark: '#317A91',
    bluePastel: '#A1E2F7',
    red: '#ff1825',
    redMedium: '#CF6746',
    redPastel: '#F4B5A1',
    yellow: '#FFFF00',
    yellowMedium: '#E3AD1A',
    yellowPastel: '#FAE2A0',
    gold: '#c5a511',
    darkRed: '#a9161f',
    darkerRed: '#5e1316',
    errorRed: '#FF0000',
    black: '#000',
    paragraph: '#5D5C5C',
    grayDarker: '#333',
    grayDark: '#555',
    gray: '#999',
    grayLight: '#bbb',
    grayLightMedium: '#CCCACA',
    grayLightHigh: '#f9f9f9',
    grayLighter: '#e5e5e5',
    offWhite: '#F3F4F6',
    orangeYellow: '#FF8300',
    white: '#fff',
    error: '#b9161f',
    info: '#22abab',
    green: '#175C1A',
    brightGreen: '#18991E',

    zircon: '#F4F8FF',
    downriver: '#0A2540',
    cobalt: '#004EBA',

    // useful for setting parent opacity without altering children
    opacity: (opacity = 1) => `rgba(0,0,0,${opacity})`,

    // Safari 14 needs different transparency  values for each colour to work
    transparent: 'rgba(229, 229, 229, 0)',
    transparentGrayLighter: 'rgba(229, 229, 229, 0)',
  },

  fontSizes: {
    xxxsmall: 10,
    xxxs: 10,
    xxsmall: 12,
    xxs: 12,
    xsmall: 13,
    xs: 13,
    small: 14,
    sm: 14,
    medium: 16,
    md: 16,
    large: 18,
    lg: 18,
    larger: 20,
    lgr: 20,
    xlarge: 24,
    xlg: 24,
    xxlarge: 36,
    xxlg: 36,
    xxxlarge: 48,
    xxxlg: 48,
    xxxxlarge: 64,
    xxxxlg: 64,
  },

  spacing: {
    none: 0,
    xxs: 2,
    xxsmall: 2,
    xs: 4,
    xsmall: 4,
    sm: 8,
    small: 8,
    smedium: 12,
    md: 16,
    medium: 16,
    larger: 24,
    lg: 32,
    large: 32,
    xlg: 48,
    xlarge: 48,
    xxlarge: 64,
    xxlg: 64,
    xxxlarge: 96,
    xxxlg: 96,
  },

  zIndex: {
    // must be more than 100 as PayPal button sets at 100
    mobileMenu: 110,
    mobileNavBar: 115,
    contactModal: 120,
    walkthroughModal: 9999,
  },
  fonts: {
    primary: 'Montserrat, sans-serif',
    secondary: 'Lato, sans-serif',
  },
  effects: {
    shadow: '2px 2px 10px gray',
    lightShadow: '2px 2px 5px gray',
  },
  componentHeights: {
    headerMobile: 60,
    headerMobileMd: 70,
    headerDesktop: 100,
  },
  breakpoints: {
    sm: 450,
    lg: 855,
    xlg: 1080,
    xxlg: 1450,
    xxxlg: 1770,
  },
}

export default theme
