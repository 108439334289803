// https://developers.facebook.com/docs/marketing-api/audiences-api/pixel

export const getFbcFbpValues = () =>
  typeof window !== 'undefined' && {
    fbc: document.cookie.replace(/(?:(?:^|.*;\s*)_fbc\s*\=\s*([^;]*).*$)|^.*$/, '$1'),
    fbp: document.cookie.replace(/(?:(?:^|.*;\s*)_fbp\s*\=\s*([^;]*).*$)|^.*$/, '$1'),
  }

export const createFbEventId = () => {
  const timestamp = Date.now().toString(36)
  const randomString = Math.random().toString(36).substring(2, 15)
  return timestamp + randomString
}

const fbqEvent = (event, params, eventID) => window.fbq && window.fbq('track', event, params, eventID && eventID)

export const fbqCompletedRegistration = () => {
  fbqEvent('CompleteRegistration', { ...getFbcFbpValues() })
}

export const fbqInitiatedCheckout = ({ product, currency, amount }) => {
  fbqEvent('InitiateCheckout', {
    type: 'product',
    content_category: 'package',
    content_ids: [product],
    value: amount,
    currency,
    ...getFbcFbpValues(),
  })
}

export const fbqPurchased = ({ product, currency, amount }) => {
  fbqEvent('Purchase', {
    type: 'product',
    content_category: 'package',
    content_ids: [product],
    value: amount,
    currency,
    ...getFbcFbpValues(),
  })
}

export const fbqLead = ({ type }, eventID) => {
  fbqEvent('Lead', { type, ...getFbcFbpValues() }, { eventID })
}
