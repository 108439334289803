import { useEffect } from 'react'
import { useState } from 'react'
import { breakpoints } from 'theme/breakpoints'

export const useWindowSize = (size) => {
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
    isWidthScreen: false,
  })
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        isWidthScreen: window.innerWidth < breakpoints[size],
      })
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [size])

  return windowSize
}
