import { differenceInSeconds, format, parseJSON } from 'date-fns'

// 9-Sep
export const DATE_NO_YEAR = 'd-LLL'

// 9-Sep-2020
export const DATE_FORMAT = 'd-LLL-YYY'

// 9:20 PM
export const TIME_FORMAT = 'p'

// (GMT+1)
export const TIMEZONE = '(O)'

// 9th September 2020
const DATE_STANDARD = 'do MMMM yyyy'

const LONG_LOCAL_DATE_TIME = `${DATE_FORMAT} @ ${TIME_FORMAT}`

export const getSecondsLeft = (futureDate) => differenceInSeconds(futureDate, new Date())

export const getMostRecentDate = (datesArray) => {
  return new Date(
    Math.max.apply(
      null,
      datesArray.map((date) => new Date(date).getTime()),
    ),
  )
}

export const getTimeUnits = (seconds) => ({
  days: Math.floor(seconds / (3600 * 24)),
  hours: Math.floor((seconds % (3600 * 24)) / 3600),
  minutes: Math.floor((seconds % 3600) / 60),
  seconds: Math.floor(seconds % 60),
})

// 9-Sep-2020 @ 9:20 PM (GMT+1)
export const mySqlToLongDateTime = (iso) =>
  format(parseJSON(iso), `${DATE_FORMAT} @ ${TIME_FORMAT} ${TIMEZONE}`)

// 9-Sep
export const mySqlToDateNoYear = (iso) => format(parseJSON(iso), DATE_NO_YEAR)

// 9-Sep-2020 @ 9:20 PM
export const mySqlToLongLocalDateTime = (iso) => format(parseJSON(iso), LONG_LOCAL_DATE_TIME)
export const dateToLongLocalDateTime = (date) => format(date, LONG_LOCAL_DATE_TIME)

// 9-Sep-2020
export const mySqlToLocalDate = (iso) => format(parseJSON(iso), DATE_FORMAT)

export const mySqlToShortDate = (date) => format(parseJSON(date), DATE_STANDARD)

// Jan, Feb, Mar
export const getMonthText = (iso) => format(parseJSON(iso), 'MMM')

export const formatTime = (iso) => format(parseJSON(iso), TIME_FORMAT)

export const dateIsValid = (date) => !Number.isNaN(new Date(date).getTime())
