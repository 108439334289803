import { useState } from 'react'
import { useLocalStorage } from './'

export const useWalkThrough = () => {
  const [showWalkThroughModal, setShowWalkThroughModal] = useState(true)
  const [reVisitWalkThrough, setReVisitWalkThrough] = useState(false)
  const [hasViewedStarterWalkThrough, setHasViewedStarterWalkThrough] = useLocalStorage(
    'hasViewedStarterWalkThrough',
    false,
  )
  const [hasViewedFoundationWalkThrough, setHasViewedFoundationWalkThrough] = useLocalStorage(
    'hasViewedFoundationWalkThrough',
    false,
  )
  const [hasViewedAcceleratorWalkThrough, setHasViewedAcceleratorWalkThrough] = useLocalStorage(
    'hasViewedAcceleratorWalkThrough',
    false,
  )

  const handleStarterWalkThroughModalClose = () => {
    setShowWalkThroughModal(false)
    setReVisitWalkThrough(false)
    setHasViewedStarterWalkThrough(true)
  }

  const handleFoundationWalkThroughModalClose = () => {
    setShowWalkThroughModal(false)
    setReVisitWalkThrough(false)
    setHasViewedFoundationWalkThrough(true)
  }

  const handleAcceleratorWalkThroughModalClose = () => {
    setShowWalkThroughModal(false)
    setReVisitWalkThrough(false)
    setHasViewedAcceleratorWalkThrough(true)
  }

  const handleWalkThroughModalOpen = () => {
    setShowWalkThroughModal(true)
    setReVisitWalkThrough(true)
  }

  return {
    showWalkThroughModal,
    reVisitWalkThrough,
    hasViewedStarterWalkThrough,
    hasViewedFoundationWalkThrough,
    hasViewedAcceleratorWalkThrough,
    handleWalkThroughModalOpen,
    handleStarterWalkThroughModalClose,
    handleFoundationWalkThroughModalClose,
    handleAcceleratorWalkThroughModalClose,
  }
}
