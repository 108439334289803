import React from 'react'
import { ThemeProvider } from 'styled-components'
import { BaseCSS as BSGridBaseCSS } from 'styled-bootstrap-grid'
import NormalizeGlobalStyle from './NormalizeGlobalStyle'
import getTheme from './index'

const ESThemeProvider = ({ children }) => (
  <ThemeProvider theme={getTheme()}>
    <>
      <NormalizeGlobalStyle />
      <BSGridBaseCSS />
      {children}
    </>
  </ThemeProvider>
)

export default ESThemeProvider
