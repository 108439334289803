import React from 'react'
import pick from 'lodash/pick'
import bugsnag from '@bugsnag/js'
import bugsnagReact from '@bugsnag/plugin-react'
import getConfig from 'next/config'

const { serverRuntimeConfig, publicRuntimeConfig } = getConfig()

const bugsnagClient = bugsnag({
  apiKey: serverRuntimeConfig.BUGSNAG_API_KEY || publicRuntimeConfig.BUGSNAG_API_KEY,
  notifyReleaseStages: ['production', 'staging'],
  releaseStage: publicRuntimeConfig.BUGSNAG_RELEASE_STAGE,
  autoCaptureSessions: publicRuntimeConfig.BUGSNAG_RELEASE_STAGE === 'production',
  beforeSend(report) {
    // Neither service workers nor jQuery is used by the app;
    // it is not clear which 3rd party service tries to use this
    if (
      report.errorMessage.indexOf('jQuery') > -1 ||
      report.errorMessage.indexOf('ServiceWorkerRegistration') > -1
    ) {
      return false
    }
  },
})

bugsnagClient.use(bugsnagReact, React)

bugsnagClient.setContext = currentUrl => {
  bugsnagClient.context = currentUrl
}

bugsnagClient.setUser = user => {
  bugsnagClient.user = user ? pick(user, ['id', 'email', 'firstName', 'lastName']) : null
}

export default bugsnagClient
