const gtmEvent = options => {
  if (window.dataLayer) {
    window.dataLayer.push(options)
  }
}

export const gtmPurchased = ({ amount, currency, product, promoCode }) =>
  gtmEvent({
    event: 'purchase',
    ecommerce: {
      currencyCode: currency,
      purchase: {
        actionField: {
          id: product, // Transaction ID (different to Product ID)
          affiliation: 'Entrepreneur Seminar',
          revenue: amount.toFixed(2), // Total amount to pay (without currency symbol)
          tax: '0', // Included in revenue
          shipping: '0',
          coupon: promoCode,
        },

        // List of productFieldObjects.
        // since purchases will only ever be a single product at once, just add the one here
        products: [
          {
            name: product,
            id: product,
            price: amount.toFixed(2),
            brand: 'Entrepreneur Seminar',
            quantity: 1, // quantity will always be 1
          },
        ],
      },
    },
  })
