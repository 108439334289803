import React from 'react'
import styled, { useTheme } from 'styled-components'
import { View } from '@components/layout/View/View'
import { chunk } from 'lodash'
import { media } from 'styled-bootstrap-grid'
import LogoLink from '../../../brand/Logo/LogoLink'
import SocialSvgIcon from '../../../images/SocialSvgIcon'
import { Anchor, P } from '../../../typography'
import { mainNavItemGroups, secondaryNavItems, socialLinks } from './content'

const StyledFooter = styled.footer`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.colors.white};
  padding: ${(props) => props.theme.spacing.md}px;
  width: 100%;

  ${media.lg`
    padding: ${(props) => props.theme.spacing.lg}px;
  `}
`

const Top = styled(View)`
  justify-content: center;
  padding-top: ${(props) => props.theme.spacing.lg}px;
  border-top: 1px solid ${(props) => props.theme.colors.grayLighter};
  max-width: 1820px;

  ${media.xl`
    align-self: center;
    padding: 0;
    border: none;
  `}
`

const Centered = styled(View)`
  align-self: center;
  padding-bottom: ${(props) => props.theme.spacing.sm}px;

  ${media.lg`
    flex-direction: row;
  `}
`

const NavItemGroupChunk = styled(View)`
  flex-direction: row;
  justify-content: center;
`

const NavItemGroup = styled(View)`
  padding: 0 ${(props) => props.theme.spacing.md}px 0;
  flex: 1;
  width: 150px;
  margin-bottom: ${(props) => props.theme.spacing.sm}px;
`

const NavGroupTitle = styled(P)`
  font-weight: bold;
  margin-bottom: ${(props) => props.theme.spacing.sm}px;
  flex-shrink: 0;
  color: ${(props) => props.theme.colors.black};
`

const Bottom = styled(View)`
  padding-top: ${(props) => props.theme.spacing.lg}px;
  flex: 1;
  max-width: 1820px;
  align-items: center;
  border-top: 1px solid ${(props) => props.theme.colors.grayLighter};

  ${media.lg`
    align-items: stretch;
    justify-content: space-between;
    flex-direction: row;
  `}

  @media (min-width: 1920px) {
    width: 1820px;
    align-self: center;
  }
`

const FooterLogo = styled(LogoLink)`
  height: 80px;
  margin-bottom: 24px;

  ${media.lg`
    height: 50px;
    margin-right: ${(props) => props.theme.spacing.sm}px;
  `}

  ${media.xl`
    height: 60px;
  `}
`

const SiteLink = styled(Anchor)`
  text-decoration-line: none;
  font-size: ${(props) => props.theme.fontSizes.xxs}px;
  margin-bottom: ${(props) => props.theme.spacing.sm}px;
  text-align: center;

  ${media.lg`
    font-size: ${(props) => props.theme.fontSizes.xs}px;
    margin-right: ${(props) => props.theme.spacing.md}px;
  `}
`

const MainSiteLink = styled(SiteLink)`
  text-align: left;
  font-size: ${(props) => props.theme.fontSizes.sm}px;
  margin-right: 0;
`

const LinksAndSocial = styled(View)`
  flex: 1;
  flex-direction: column-reverse;

  ${media.lg`
     flex-direction: row;
     justify-content: flex-end;
     align-items: flex-start;
  `}
`

const SecondaryLinks = styled(View)`
  align-items: center;
  margin-bottom: ${(props) => props.theme.spacing.sm}px;

  ${media.lg`
    flex-direction: row;
  `}
`

const Description = styled(P)`
  font-size: ${(props) => props.theme.fontSizes.xxs}px;
  text-align: center;
  padding: 0 ${(props) => props.theme.spacing.lg}px;

  ${media.lg`
    padding: 0;
    max-width: 700px;
    text-align:right;
  `}
`

const SocialIcons = styled(View)`
  flex-direction: row;
  justify-content: center;
`

const SocialIcon = styled(View)`
  justify-content: center;
  align-items: center;
  padding: 0 ${(props) => props.theme.spacing.md}px;
  margin-bottom: ${(props) => props.theme.spacing.md}px;

  ${media.lg`
    margin-bottom: 0;
  `}
`

export const Footer = () => {
  const theme = useTheme()
  return (
    <StyledFooter>
      <Top>
        <Centered>
          {chunk(mainNavItemGroups, 2).map((groupsChunk) => (
            <NavItemGroupChunk key={groupsChunk[0].title}>
              {groupsChunk.map((group) => (
                <NavItemGroup key={group.title}>
                  <NavGroupTitle>{group.title}</NavGroupTitle>
                  {group.items.map((item) => (
                    <MainSiteLink
                      key={item.title}
                      href={item.href}
                      {...(item.anchorProps ? item.anchorProps : {})}
                    >
                      {item.title}
                    </MainSiteLink>
                  ))}
                </NavItemGroup>
              ))}
            </NavItemGroupChunk>
          ))}
        </Centered>
      </Top>

      <Bottom>
        <View>
          <FooterLogo />
        </View>

        <View>
          <LinksAndSocial>
            <SecondaryLinks>
              {secondaryNavItems.map(({ href, title }) => (
                <SiteLink key={title} href={href}>
                  {title}
                </SiteLink>
              ))}
            </SecondaryLinks>

            <SocialIcons>
              {socialLinks.map(({ icon, href }) => (
                <a key={icon} href={href} target="_blank" rel="noopener noreferrer">
                  <SocialIcon>
                    <SocialSvgIcon size={25} color={theme.colors.grayLight} name={icon} />
                  </SocialIcon>
                </a>
              ))}
            </SocialIcons>
          </LinksAndSocial>

          <Description>
            Entrepreneur Seminar is a trading name for Entrepreneur Insight Limited.
            <br />
            Copyright | ©{new Date().getFullYear()} All rights reserved Company No: 11408037
          </Description>
        </View>
      </Bottom>
    </StyledFooter>
  )
}
