import { useMutation, useQueryClient } from 'react-query'
import esAPI from 'services/esAPI/esAPI'

export const useCancelSubscription = () => {
  const queryClient = useQueryClient()

  const mutation = useMutation(
    'cancel-subscription',
    () => esAPI('delete', '/stripe/subscription'),
    {
      onSuccess: (subscription) => queryClient.setQueryData('subscription-query', subscription),
    },
  )

  return { ...mutation, cancelSubscription: mutation.mutate }
}
