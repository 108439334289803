import styled, { css } from 'styled-components'
import { media } from 'styled-bootstrap-grid'

export const h2Styles = css`
  font-size: ${(props) => props.theme.fontSizes.xlg}px;
  font-family: Montserrat, sans-serif;
  font-weight: bold;
  letter-spacing: 0px;
  margin-bottom: ${(props) => props.theme.spacing.sm}px;
  margin-block-start: ${(props) => props.theme.spacing.md}px;

  ${media.md`
    font-size: ${(props) => props.theme.fontSizes.xxlg}px;
  `}

  @media (min-width: 1520px) {
    font-size: ${(props) => props.theme.fontSizes.xxxlg}px;
  }
`

const H2 = styled.h2`
  ${h2Styles}
`

/** @component */
export default H2
