import styled from 'styled-components'
import P from '../P/P'

export const ListItemText = styled(P)`
  color: ${props => (props.colored ? props.theme.colors.primary : props.theme.colors.black)};
  text-decoration: ${props => (props.underline ? 'underline' : 'none')};
  font-style: ${props => (props.italics ? 'italic' : 'normal')};
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
  line-height: 25px;
`
