import { createGlobalStyle } from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import styledNormalize from 'styled-normalize'

const NormalizeGlobalStyle = createGlobalStyle`
  ${styledNormalize}

  * {
    box-sizing: border-box;
  }

  body {
    background: #fff;
  
    font-size: 18px;
    font-weight: normal;
    font-family: 'Montserrat', sans-serif;
    
    ${media.sm`
      font-size: 24px;
    `}
    
    p {
      margin: 0;
    }
    
    a {
      text-decoration: none;
    }
    
    button:hover, a:hover {
      cursor: pointer;
    }
    
    a:focus, a:active,
    button:focus, button:active {
      outline: none;
    }
  }
`

export default NormalizeGlobalStyle
