import { useMutation, useQueryClient } from 'react-query'
import noop from 'lodash/noop'
import { successToast } from 'services/Notification/toastNotifications'
import esAPI from 'services/esAPI/esAPI'

export const useUpdateUserDetailsMutation = ({ onSuccess = noop } = {}) => {
  const queryClient = useQueryClient()

  const mutation = useMutation(
    'update-user-details-mutation',
    (vals) => esAPI('post', '/auth/update-user', vals),
    {
      onSuccess: (user) => {
        queryClient.setQueryData('user-query', { user })
        successToast('Account Details Updated!')
        onSuccess()
      },
    },
  )

  return { ...mutation, updateUserDetails: mutation.mutateAsync }
}
