import { useQuery } from 'react-query'
import esAPI from '@services/esAPI/esAPI'

const initialData = {
  acceleratorProgram: null,
  raisingFundsForBusinessProgram: null,
  bonusSessions: [],
  coreSessions: [],
  foundationProgram: null,
  hasSeenWelcomeVideo: false,
  instalmentPlan: null,
  mentoringActive: false,
  mentoringExpiryDate: null,
  monthlyCoreSession: [],
  paymentDate: null,
  program: null,
  programText: 'No ',
  starterProgram: null,
  subscription: null,
  teachingSessions: [],
  unlockedContent: {},
  programExpired: null,
  programExpiryDate: null,
}

export const useContentAvailableQuery = (options = {}) => {
  const query = useQuery('content-available', () => esAPI('get', '/course/content-available'), {
    initialData,
    ...options,
  })

  return { ...query, contentAvailable: query.data }
}
