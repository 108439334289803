// All global css must be imported here since Next.js 11
import { Elements as StripeElements } from '@stripe/react-stripe-js'
import React from 'react'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Context as ResponsiveContext } from 'react-responsive'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'draft-js/dist/Draft.css'
import 'isomorphic-unfetch'
import NextJSApp from 'next/app'
import Router from 'next/router'
import { queryClient } from 'services/queryClient/queryClient'
import { stripePromise } from 'services/stripe/stripePromise'
import fontSources from 'theme/fontSources'
import 'video.js/dist/video-js.css'
import { PurchaseProvider } from '@containers/PurchaseApi/PurchaseContainer'
import { TokenSignInProvider } from '@containers/UserContext/TokenSignInContainer'
import '../src/components/input/FormattedInput/formattedInput.css'
import '../src/components/input/Input/react-widgets-styles/import.scss'
import PageLayout from '../src/components/layout/PageLayout/PageLayout'
import '../src/components/layout/SlickSlider/slick-theme.css'
import '../src/components/layout/SlickSlider/slick.css'
import { UserProvider } from '../src/containers/UserContext/UserContext'
import WebfontProvider from '../src/services/WebFontLoader/WebfontLoader'
import bugsnagClient from '../src/services/bugsnag/bugsnag'
import { gtagPageView } from '../src/services/engagementTracking'
import ESThemeProvider from '../src/theme/ESThemeProvider'
import { deviceWidthParser } from '../src/theme/server'
import './index.css'

Router.events.on('routeChangeComplete', gtagPageView)

const ErrorBoundary = bugsnagClient.getPlugin('react')

class App extends NextJSApp {
  static getInitialProps = async ({ ctx }) => ({
    device: ctx.req && deviceWidthParser(ctx.req.headers['user-agent']),
  })

  render() {
    const { Component, pageProps, device, user } = this.props
    return (
      <>
        <ErrorBoundary /*FallbackComponent={Error}*/>
          <QueryClientProvider client={queryClient}>
            <ESThemeProvider>
              <WebfontProvider>
                <ResponsiveContext.Provider value={device}>
                  <UserProvider user={user}>
                    <StripeElements options={{ fonts: fontSources }} stripe={stripePromise}>
                      <PurchaseProvider>
                        <PageLayout {...pageProps}>
                          <TokenSignInProvider>
                            <Component {...pageProps} />
                          </TokenSignInProvider>
                        </PageLayout>
                      </PurchaseProvider>
                    </StripeElements>
                  </UserProvider>
                </ResponsiveContext.Provider>
              </WebfontProvider>
            </ESThemeProvider>
            <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
          </QueryClientProvider>
          <ToastContainer
            position="top-center"
            autoClose={2000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            style={{ fontSize: '16px', fontWeight: 'bold' }}
          />
        </ErrorBoundary>
      </>
    )
  }
}

export default App
