import React, { useEffect } from 'react'
import styled from 'styled-components'
import { View } from '@components/layout/View/View'
import { useUrlParams } from 'hooks'
import { media } from 'styled-bootstrap-grid'
import { HeaderDesktop } from './Desktop'
import { HeaderMobile } from './Mobile'

const HeaderContainer = styled.header`
  width: 100%;
  display: flex;
`

const SmallScreen = styled(View)`
  flex: 1;
  flex-direction: row;
  ${media.xl`
    display: none;
  `};
`

const LargeScreen = styled(View)`
  display: none;

  ${media.xl`
    display: flex;
    flex: 1;
    flex-direction: row;
  `};
`

const Header = (props) => {
  const { storeUrlParams } = useUrlParams()

  useEffect(() => {
    storeUrlParams()
  }, [])

  return (
    <HeaderContainer>
      <SmallScreen>
        <HeaderMobile {...props} />
      </SmallScreen>

      <LargeScreen>
        <HeaderDesktop {...props} />
      </LargeScreen>
    </HeaderContainer>
  )
}

export default Header
